@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #0056ae;
  --background-color: #ffffff;
  --text-color: #000000;
}

.dark {
  --primary-color: #ee2434;
  --background-color: #1a202c;
  --text-color: #ffffff;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}
